import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useState, useRef, useEffect } from 'react';

firebase.initializeApp({
  apiKey: "AIzaSyD_E_4Dm8XHD-1YlyPDLbhW0GSOQHiuSIg",
  authDomain: "hkm-gatsby.firebaseapp.com",
  projectId: "hkm-gatsby",
  storageBucket: "hkm-gatsby.appspot.com",
  messagingSenderId: "583457448579",
  appId: "1:583457448579:web:f1336269e370bad3a071b6"
});

const firestore = firebase.firestore();

const signInWithGoogle = () => {
  const provider = new firebase.auth.GoogleAuthProvider()
  firebase.auth().signInWithPopup(provider)
};

const messagesRef = firestore.collection('whatsappMessages_v2');

const fetchStoredMedia = async (media_id, mime_type, hkm_phone_code) => {
  const response = await fetch(`https://folkworld.org/dmt/wa-media/?media_id=${media_id}&media_type=${mime_type.split('/')[1]}&phone_code=${hkm_phone_code}`);
  const data = await response.json();
  return data.url;
}

function App() {
  const [user, loading] = useAuthState(firebase.auth())
  const dummy = useRef()

  const [uniqPhoneNumbers, setUniqPhoneNumbers] = useState();
  const [viewPhone, setViewPhone] = useState()
  const [messages, setMessages] = useState()
  const [searchPhone, setSearchPhone] = useState()
  const [medias, setMedias] = useState({})
  const [reload, setReload] = useState(true);

  useEffect(() => {

    const fetchChats = async () => {
      setUniqPhoneNumbers();
  
      let messagesQuery = messagesRef.orderBy('sentAt', 'desc').limit(200);
      if (searchPhone) {
        messagesQuery = messagesRef.where('phoneId', '==', searchPhone).orderBy('sentAt', 'desc').limit(10);
      }
  
      const snapshot = await messagesQuery.get();
      
      const extractedPhoneNumbers = snapshot && snapshot.docs && (snapshot.docs.length > 0) && snapshot.docs.reduce((ro, snap) => {
        const data = snap.data()
        const key = data.phoneId + '|' + data.hkm_phone_code
        if (!ro[key] || ro[key] === 'Unknown') {
          ro[key] = data.contact_name || 'Unknown'
        }
        return ro
      }, {})
  
      setUniqPhoneNumbers(extractedPhoneNumbers || {})
      setReload();
    }

    if (reload || searchPhone) {
      fetchChats();
    }

    return () => {}
  }, [searchPhone, reload])

  if (!user && !loading) {
    return (<>
      <button onClick={signInWithGoogle} className="btn btn-lg btn-primary">Login with google</button>
    </>)
  }

  const fetchPhoneMessage = async (e) => {
    e.preventDefault()
    const phone = e.target.elements[0].value.trim()
    if (!phone) { return }
    setSearchPhone(phone)
  }

  const fetchMessages = async (text) => {
    const [phoneId, phoneCode] = text.split('|');

    const messagesQuery = firestore.collection('whatsappMessages_v2').where('phoneId', '==', phoneId).where('hkm_phone_code', '==', phoneCode).orderBy('sentAt', 'desc').limit(200)

    const messagesData = await messagesQuery.get()

    setMessages(messagesData && messagesData.docs && messagesData.docs.map(snap => {
      const { sentAt, readAt, deliveredAt, message, messageType, phoneId, hkm_phone_code, type, contact_name, templateName, status } = snap.data()
      if (uniqPhoneNumbers[text] === 'Unknown' && contact_name) {
        uniqPhoneNumbers[text] = contact_name;
        setUniqPhoneNumbers({...uniqPhoneNumbers})
      }
      return { sentAt, readAt, deliveredAt, message, messageType, phoneId, hkm_phone_code, type, contact_name, templateName, status }
    }).sort((x, y) => x.sentAt - y.sentAt));

    setTimeout(() => {
      dummy.current && dummy.current.scrollIntoView({ behavior: 'smooth' })
    }, 1)
  }

  const openMessagePane = (phone) => {
    setViewPhone(phone)
    fetchMessages(phone)
  }

  const showPhone = (text) => {
    if (!text) {
      return '';
    }
    const [phoneNo, phoneCode] = text.split('|')
    return `${phoneNo} <small className='text-muted'>(${phoneCode})</small>`
  }

  const getMessage = (message) => {
    if (message.messageType === 'text') {
      return message.message && message.message.body && message.message.body.split('\n').join('<br />');
    }

    if (message.messageType === 'button') {
      return message.message && message.message.text && message.message.text.split('\n').join('<br />');
    }

    if (message.messageType === 'reaction') {
      return message.message && message.message.emoji;
    }

    if (message.messageType === 'contacts') {
      return message.message && message.message.map(m => {
        return `${m.name.formatted_name}: ${m.phones.map(p => p.phone).join(',')}`
      }).join('<br />');
    }

    return message.message && message.message.caption && message.message.caption.split('\n').join('<br />');
  }

  const formatStatus = (status) => {
    if (status === 'read') {
      return (<>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" viewBox="0 0 16 16">
          <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z"/>
          <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z"/>
        </svg>
      </>)
    }

    if (status === 'delivered') {
      return (<>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="grey" viewBox="0 0 16 16">
          <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z"/>
          <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z"/>
        </svg>
      </>)
    }

    return (<>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="grey" viewBox="0 0 16 16">
        <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
      </svg>
    </>)
  }

  const setMessageMediaLink = (media_id, media_url) => {
    medias[media_id] = media_url;
    setMedias({...medias});
  }

  return (
    <div className="container">
      <div className={"col-sm-4" + (viewPhone ? " list-mobile-pane" : "")}>
        <div className="row">
          <div className="chat-header">
            <h4>Conversations
            <div className='pull-right' onClick={() => {
              setReload(true);
              setSearchPhone();
            }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
              </svg>
            </div>
            </h4>
          </div>
          <div className="form-group" style={{ marginBottom: 0 }}>
            <form onSubmit={fetchPhoneMessage}>
              <input className="form-control" type="text" placeholder="Search phone no. and hit enter!" />
            </form>
          </div>
          <ul className="list-group">
            {uniqPhoneNumbers && Object.keys(uniqPhoneNumbers).map((phone, index) => (<>
              <li className={"list-group-item " + (viewPhone === phone ? "message-selected" : "")} key={index} onClick={() => openMessagePane(phone)}>
                <h4 className="list-group-item-heading" dangerouslySetInnerHTML={{ __html: showPhone(phone) }} />
                <p className="list-group-item-text">{uniqPhoneNumbers[phone]}</p>
              </li>
            </>))}
            {!uniqPhoneNumbers && (
              <li className={"list-group-item "}>
                <h4 className='text-muted'>Fetching chats..</h4>
              </li>
            )}
            {uniqPhoneNumbers && !Object.keys(uniqPhoneNumbers).length && (
              <li className={"list-group-item "}>
                <h4 className='text-muted'>No chats.</h4>
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className={"col-sm-8" + (viewPhone ? " " : " msg-mobile-pane")}>
        <div className="row">
          {messages && (<div className="panel panel-default">
            <div className="panel-heading">
              <h3 className="panel-title">{uniqPhoneNumbers && uniqPhoneNumbers[viewPhone]}
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="pull-right" style={{ marginTop: '10px' }} viewBox="0 0 16 16" onClick={() => {
                  setMessages();
                  setViewPhone();
                }}>
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </h3>
              <p style={{ marginTop: "10px" }} dangerouslySetInnerHTML={{ __html: showPhone(viewPhone) }} />
            </div>
            <div className="panel-body message-pane">
              {messages.map((message, index) => {
                let dateTime = message.sentAt ? (String(message.sentAt.toDate().toLocaleDateString()) + ' ' + String(message.sentAt.toDate().toTimeString().split(':').splice(0, 2).join(':'))) : 'Just now.';
                return(
                  <div key={index}>
                    <div className={"message bg-primary " + ((message.type === "outgoing") ? "pull-right message-reply": "pull-left")}>
                      {(message.type === "outgoing") && !message.message && (
                        <p>[Template{message.templateName ? `: ${message.templateName}` : ''}]</p>
                      )}
                      {(message.type === "outgoing") && message.message && (
                        <p>{message.message}</p>
                      )}
                      {(['text', 'button', 'contacts', 'unsupported', 'reaction'].indexOf(message.messageType) === -1) && (message.type === "incoming") && (<>
                        {message.message && <div className='image-box'>
                          {!medias[message.message.id] && <button className="btn btn-sm" onClick={async () => {
                            const media_url = await fetchStoredMedia(message.message.id, message.message.mime_type, message.hkm_phone_code);
                            setMessageMediaLink(message.message.id, media_url);
                          }}>Load media</button>}
                          {medias[message.message.id] && <a href={medias[message.message.id]} target="_blank" rel="noreferrer">View</a>}
                        </div>}
                      </>)}
                      {message.message && <p dangerouslySetInnerHTML={{__html: getMessage(message)}} />}
                      {message.status && <span className="pull-right" style={{ marginLeft: '2px' }}>{formatStatus(message.status)}</span>}
                      <small className="pull-right text-muted message-date" role="button" onClick={() => {
                        if (message.type === 'incoming') {
                          return;
                        }
                        alert(`Sent: ${dateTime}
Delivered: ${message.deliveredAt ? (String(message.deliveredAt.toDate().toLocaleDateString()) + ' ' + String(message.deliveredAt.toDate().toTimeString().split(':').splice(0, 2).join(':'))) : '-' }
Read: ${message.readAt ? (String(message.readAt.toDate().toLocaleDateString()) + ' ' + String(message.readAt.toDate().toTimeString().split(':').splice(0, 2).join(':'))) : '-' }
                        `)
                      }}>
                        {dateTime}
                        &nbsp;
                        {message.messageType && <>({message.messageType})</>}
                      </small>
                    </div>
                    <div className="clearfix"></div>
                  </div>)
                })
              }
              <span ref={dummy}></span>
            </div>
          </div>)}
          {!messages && (
            <div className='panel panel-default'>
              <div className={"panel-heading no-selection text-muted"}>
                Select a chat!
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
